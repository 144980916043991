.popover {
  background-color: #c6c6c8;
  background-image: linear-gradient(
    152deg,
    #c6c6c8 55%,
    #eeeeee 95%,
    #eeeeee 120%
  );
  max-width: 640px;

  .popover-arrow {
    display: none;
  }

  .popover-footer {
    background-image: url('/assets/images/paperplane-small.png');
    background-repeat: no-repeat;
    background-position: 92% 0;
    background-size: 100px auto;
    color: $color-white;
    padding: 25px 45px 95px 54px;
  }

  .popover-body {
    background-image: url('/assets/images/logo-hmh.png');
    background-repeat: no-repeat;
    background-position: 30px 30px;
    background-size: 90px auto;
    color: $color-white;
    padding: 125px 63px 15px 63px;

    strong {
      font-family: $font-robotomedium;
      font-weight: inherit;
      font-size: 0.975rem;
    }

    a {
      color: $color-white;
      text-decoration: none;
      transition: color 0.35s cubic-bezier(0, 0, 0.23, 1);

      &:hover {
        color: $color-grey-dark;
      }
    }
  }
}
