.modal {
  .modal-content {
    background-color: $color-grey-bright;
    border: none;

    .modal-header {
      border-bottom: none;

      .modal-title {
        color: $color-grey-dark;
        font-family: $font-robotoregular;
        text-transform: uppercase;
      }
    }

    .modal-body {
      margin: 0 15px;

      p {
        color: $color-grey-dark;
      }

      a {
        color: $color-grey-dark;
        text-decoration: none;
        transition: color 0.35s cubic-bezier(0, 0, 0.23, 1);

        &:hover {
          color: $color-white;
        }
      }

      ul {
        margin: 20px 0 15px 0 !important;
        padding-left: 30px !important;

        li {
          color: $color-grey-dark !important;
          line-height: 1.4 !important;
          padding-bottom: 5px !important;
        }
      }

      .row > * {
        padding-right: 0;
        padding-left: 0;
      }
    }
  }
}
