.hmh-intro {
  padding: 35px 0 55px 0;

  #hmh-h1 {
    font-size: calc(1.325rem + 0.5vw);
    text-transform: uppercase;
    margin-bottom: 20px;
    font-weight: 600;
    letter-spacing: 1px;

    @include media-breakpoint-up(md) {
      font-size: 22px;
      letter-spacing: $letterSpacingDesktop;
    }
  }

  p {
    text-align: left;

    @include media-breakpoint-up(md) {
      text-align: justify;
      hyphens: auto;
      font-size: 20px;
    }
  }

  #hmh-world {
    margin-top: 30px;
    width: 100%;
    max-width: 80px;
    height: auto;
    margin-left: 50%;
    transform: translate(-50%, 0);
  }
}
