html {
  -webkit-font-smoothing: antialiased;
}

body {
  font-family: $font-robotolight;
  font-weight: 300;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: $color-grey-mid;
  color: $color-white;

  &.home {
    background-color: #c6c6c8;
    background-image: linear-gradient(
      152deg,
      #c6c6c8 0%,
      #eeeeee 9%,
      #c6c6c8 20%
    );

    @include media-breakpoint-up(md) {
      background-image: linear-gradient(
        152deg,
        #c6c6c8 0%,
        #eeeeee 14%,
        #c6c6c8 30%
      );
    }

    @include media-breakpoint-up(lg) {
      background-image: linear-gradient(
        152deg,
        #c6c6c8 0%,
        #eeeeee 18%,
        #c6c6c8 34%
      );
    }

    .hmh-footer {
      padding: 15px 0 40px 0;

      @include media-breakpoint-up(md) {
        padding: 15px 0 100px 0;
      }
    }
  }

  .hmh-box {
    padding: 0 15px;
    background-color: $color-grey-bright;
    border-radius: 5px;
    position: relative;

    h2 {
      float: right;
      font-size: $btnSizeMobile;
      padding: 5px 10px;
      text-transform: uppercase;
      border-radius: 5px;
      background-color: $color-grey-mid;
      font-weight: 600;
      letter-spacing: $letterSpacingMobile;

      @media not all and (min-resolution: 0.001dpcm) {
        @media all {
          letter-spacing: $letterSpacingMobileSafari;
        }
      }

      @include media-breakpoint-up(sm) {
        font-size: 12px;
      }

      @include media-breakpoint-up(md) {
        font-size: 14px;
        letter-spacing: $letterSpacingDesktop;

        @media not all and (min-resolution: 0.001dpcm) {
          @media all {
            letter-spacing: $letterSpacingDesktopSafari;
          }
        }
      }

      @include media-breakpoint-up(lg) {
        font-size: 16px;
      }
      @include media-breakpoint-up(xl) {
        font-size: 22px;
      }
    }

    ul {
      margin: 35px 0 0 0;
      padding-left: 15px;

      li {
        &:last-child {
          padding-bottom: 20px;
        }
        color: $color-grey-dark;
        line-height: 1.4;
        padding-bottom: 10px;
      }
    }

    p {
      margin-top: 35px;
      text-align: left;
      color: $color-grey-dark;

      @include media-breakpoint-up(md) {
        text-align: justify;
        hyphens: auto;
      }
    }

    &.hmh-box-inquiry {
      margin: 15px 0;
      padding: 20px 20px;
      min-height: auto;

      @include media-breakpoint-up(sm) {
        padding: 15px 15px;
        min-height: 470px;
      }
      @include media-breakpoint-up(md) {
        padding: 20px 20px;
        min-height: 460px;
      }
      @include media-breakpoint-up(lg) {
        padding: 20px 20px;
        min-height: 400px;
      }
      @include media-breakpoint-up(xl) {
        padding: 25px 25px;
        min-height: 380px;
      }

      &.hmh-box-features {
        min-height: auto;

        @include media-breakpoint-up(sm) {
          min-height: 490px;
        }
        @include media-breakpoint-up(md) {
          min-height: 500px;
        }
        @include media-breakpoint-up(lg) {
          min-height: 440px;
        }
        @include media-breakpoint-up(xl) {
          min-height: 400px;
        }
      }
    }

    &.dark {
      margin: 25px 0;
      padding: 20px 20px 10px 20px;
      background-color: $color-grey-mid-2;

      @include media-breakpoint-up(sm) {
        padding: 15px 15px 10px 15px;
      }
      @include media-breakpoint-up(md) {
        padding: 20px 20px 10px 20px;
      }
      @include media-breakpoint-up(lg) {
        padding: 20px 20px 10px 20px;
      }
      @include media-breakpoint-up(xl) {
        padding: 25px 25px 10px 25px;
      }

      p {
        color: $color-white;
        margin-top: 0;
        text-align: left;
      }

      a {
        color: $color-white;
        text-decoration: none;
        transition: color 0.35s cubic-bezier(0, 0, 0.23, 1);

        &:hover {
          color: $color-grey-dark;
        }
      }
    }
  }

  .btn-open-form {
    position: relative;
    // bottom: 20px;
    // right: 20px;
    float: right;
    font-size: $btnSizeMobile;
    padding: 5px 10px;
    text-transform: uppercase;
    border-radius: 5px;
    background-color: $color-grey-mid;
    color: $color-white;
    text-decoration: none;
    font-weight: 600;
    letter-spacing: $letterSpacingMobile;
    margin-top: 20px;
    margin-bottom: 0;
    line-height: 1.2;
    transition: background-color 0.35s cubic-bezier(0, 0, 0.23, 1);
    text-align: center;

    &:hover {
      background-color: $color-grey-dark;
    }

    @include media-breakpoint-up(sm) {
      margin-top: 0;
      font-size: 12px;
      bottom: 15px;
      right: 15px;
      position: absolute;
    }
    @include media-breakpoint-up(md) {
      font-size: 14px;
      bottom: 20px;
      right: 20px;
      letter-spacing: $letterSpacingDesktop;

      @media not all and (min-resolution: 0.001dpcm) {
        @media all {
          letter-spacing: $letterSpacingDesktopSafari;
        }
      }
    }
    @include media-breakpoint-up(lg) {
      font-size: 16px;
      bottom: 20px;
      right: 20px;
    }
    @include media-breakpoint-up(xl) {
      font-size: 22px;
      bottom: 25px;
      right: 25px;
    }

    @media not all and (min-resolution: 0.001dpcm) {
      @media all {
        letter-spacing: $letterSpacingMobileSafari;
      }
    }
  }

  .btn-hmh,
  .btn-form-send,
  .btn-form-dismiss {
    font-size: $btnSizeMobile;
    padding: 5px 10px;
    text-transform: uppercase;
    border-radius: 5px;
    background-color: $color-grey-mid;
    color: $color-white;
    text-decoration: none;
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 600;
    letter-spacing: $letterSpacingMobile;
    line-height: 1.2;
    transition: background-color 0.35s cubic-bezier(0, 0, 0.23, 1);
    text-align: center;
    border: none;
    vertical-align: middle;
    -webkit-appearance: none;

    &:hover {
      color: $color-white !important;
      background-color: $color-grey-dark;
    }

    @include media-breakpoint-up(sm) {
      font-size: 12px;
      padding: 5px 10px;
    }
    @include media-breakpoint-up(md) {
      font-size: 14px;
      padding: 5px 10px;
      letter-spacing: $letterSpacingDesktop;

      @media not all and (min-resolution: 0.001dpcm) {
        @media all {
          letter-spacing: $letterSpacingDesktopSafari;
        }
      }
    }
    @include media-breakpoint-up(lg) {
      font-size: 16px;
      padding: 5px 10px;
    }
    @include media-breakpoint-up(xl) {
      font-size: 22px;
      padding: 5px 10px;
    }

    @media not all and (min-resolution: 0.001dpcm) {
      @media all {
        letter-spacing: $letterSpacingMobileSafari;
      }
    }
  }

  .honeypot {
    position: absolute;
    left: -9999px;
  }

  strong {
    font-weight: 300;
    font-family: $font-robotomedium;
    letter-spacing: 0.1px;
  }

  .robotomedium {
    font-family: $font-robotomedium;
  }
}
