.hmh-meta {
  margin: 60px 0 0 0;

  h1 {
    text-transform: uppercase;
    font-size: 5.5vw;
    font-weight: 600;
    letter-spacing: 1px;
    margin-bottom: 20px;

    @include media-breakpoint-up(md) {
      font-size: 32px;
      letter-spacing: $letterSpacingDesktop;
    }
  }

  h3 {
    margin-bottom: 15px;
  }

  p {
    word-break: normal;
  }

  ul {
    margin: 20px 0 15px 0 !important;
    padding-left: 30px !important;

    li {
      color: $color-white !important;
      line-height: 1.4 !important;
      padding-bottom: 5px !important;
    }
  }

  .btn-error {
    padding: 15px 0 0 0;
  }
}
