.hmh-contact {
  padding: 15px 0;
  position: relative;
  display: none;

  @include media-breakpoint-up(sm) {
    display: inherit;
  }

  .hmh-box {
    img {
      position: absolute;
      right: -1px;
      width: 200px;
      height: auto;
      z-index: 0;

      @include media-breakpoint-up(sm) {
        width: 48%;
      }

      @include media-breakpoint-up(md) {
        width: 48%;
      }

      @include media-breakpoint-up(lg) {
        width: 48%;
      }

      @include media-breakpoint-up(xl) {
        width: 48%;
      }
    }

    .hmh-button-container {
      position: relative;
      display: table;
      height: 76px;
      z-index: 1;

      @include media-breakpoint-up(sm) {
        height: 92px;
      }

      @include media-breakpoint-up(md) {
        height: 100px;
      }

      @include media-breakpoint-up(lg) {
        height: 118px;
      }

      @include media-breakpoint-up(xl) {
        height: 144px;
      }

      .hmh-button-wrapper {
        display: table-cell;
        vertical-align: middle;
      }

      .btn-contact {
        float: left;
        font-size: $btnSizeMobile;
        padding: 5px 10px;
        text-transform: uppercase;
        border-radius: 5px;
        background-color: $color-grey-mid;
        color: $color-white;
        text-decoration: none;
        font-weight: 600;
        letter-spacing: 1px;
        margin-top: 0;
        margin-bottom: 0;
        line-height: 1.2;
        transition: background-color 0.35s cubic-bezier(0, 0, 0.23, 1);
        text-align: center;

        &:hover {
          background-color: $color-grey-dark;
        }

        @include media-breakpoint-up(sm) {
          font-size: 12px;
        }

        @include media-breakpoint-up(md) {
          margin: 0 0 0 10px;
          letter-spacing: $letterSpacingDesktop;
          font-size: 14px;

          @media not all and (min-resolution: 0.001dpcm) {
            @media all {
              letter-spacing: $letterSpacingDesktopSafari;
            }
          }
        }

        @include media-breakpoint-up(lg) {
          margin: 0 0 0 50px;
          font-size: 16px;
        }

        @include media-breakpoint-up(xl) {
          margin: 0 0 0 50px;
          font-size: 22px;
        }

        @media not all and (min-resolution: 0.001dpcm) {
          @media all {
            letter-spacing: $letterSpacingMobileSafari;
          }
        }
      }
    }
  }
}
