.hmh-inquiry {
  padding: 0 0;

  #hmh-icon-airplane {
    position: absolute;
    top: -6px;
    left: 10px;
    width: 85px;
    height: auto;

    @include media-breakpoint-up(sm) {
      width: 76px;
    }
    @include media-breakpoint-up(md) {
      width: 80px;
    }
    @include media-breakpoint-up(lg) {
      width: 100px;
    }
    @include media-breakpoint-up(xl) {
      width: 110px;
    }
  }
  #hmh-icon-city {
    position: absolute;
    top: -14px;
    left: 20px;
    width: 50px;
    height: auto;

    @include media-breakpoint-up(sm) {
      width: 44px;
    }
    @include media-breakpoint-up(md) {
      width: 46px;
    }
    @include media-breakpoint-up(lg) {
      width: 53px;
    }
    @include media-breakpoint-up(xl) {
      width: 55px;
    }
  }

  #hmh-icon-suitcase {
    position: absolute;
    top: 12px;
    left: 18px;
    width: 55px;
    height: auto;

    @include media-breakpoint-up(sm) {
      width: 46px;
      top: 10px;
      left: 20px;
    }
    @include media-breakpoint-up(md) {
      width: 46px;
      top: 14px;
      left: 20px;
    }
    @include media-breakpoint-up(lg) {
      top: 10px;
      left: 20px;
      width: 62px;
    }
    @include media-breakpoint-up(xl) {
      top: 18px;
      left: 22px;
      width: 70px;
    }
  }

  #hmh-icon-palmtree {
    position: absolute;
    top: -12px;
    left: 20px;
    width: 75px;
    height: auto;

    @include media-breakpoint-up(sm) {
      width: 66px;
    }
    @include media-breakpoint-up(md) {
      width: 75px;
    }
    @include media-breakpoint-up(lg) {
      width: 84px;
    }
    @include media-breakpoint-up(xl) {
      width: 88px;
    }
  }
}
