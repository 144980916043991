.hmh-header {
  #hmh-logo {
    margin-top: 30px;
    margin-left: 0;
    width: 100px;
    height: auto;
  }

  #hmh-paperplane {
    margin: 25% 0 55px 0;
    width: 110%;
    margin-left: -20%;
    max-width: 1400px;
    height: auto;
    pointer-events: none;
  }

  @include media-breakpoint-up(sm) {
    #hmh-logo {
      margin-top: 60px;
      margin-left: 0;
      width: 120px;
    }
    #hmh-paperplane {
      margin-left: 0;
      margin: -30px 0 120px 0;
      width: 85%;
    }
  }

  @include media-breakpoint-up(md) {
    #hmh-logo {
      margin-top: 70px;
      margin-left: -107px;
      width: 140px;
    }
    #hmh-paperplane {
      margin: -30px 0 120px 0;
      width: 90%;
    }
  }

  @include media-breakpoint-up(lg) {
    #hmh-paperplane {
      margin: -30px 0 120px 0;
      width: 95%;
    }
  }

  @include media-breakpoint-up(xl) {
    #hmh-paperplane {
      margin: -30px 0 120px 0;
      width: 100%;
    }
  }
}
