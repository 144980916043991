// Bootstrap 5 grid override

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
);

// @include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
// @include _assert-starts-at-zero($grid-breakpoints);

// // Grid containers
// //
// // Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 540px,
  md: 580px,
  lg: 680px,
  xl: 820px
);

// @include _assert-ascending($container-max-widths, "$container-max-widths");

// // Grid columns
// //
// // Set the number of columns and specify the width of the gutters.

// $grid-columns: 12;
$grid-gutter-width: 30px !default;
$container-padding-xs: 30px;

// $enable-grid-classes: true;

// COLORS
$color-white: #fff;
$color-black: #000;
$color-grey-dark: #5a5a5d;
$color-grey-mid: #c6c6c8;
$color-grey-mid-2: #b5b5b9;
$color-grey-bright: #dcdcdd;
$color-blue: #7cacc3;

// FONTS
$h1-font-size: 5rem;
$h2-font-size: 4rem;
$h3-font-size: 3rem;
$h4-font-size: 2rem;
$h5-font-size: 1rem;

$letterSpacingMobile: 0.05rem;
$letterSpacingDesktop: 0.1rem;
$letterSpacingMobileSafari: 0.0125rem;
$letterSpacingDesktopSafari: 0.05rem;
$btnSizeMobile: 5vw;
