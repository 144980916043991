$font-robotothin: 'robotothin';
$font-robotoregular: 'robotoregular';
$font-robotomedium: 'robotomedium';
$font-robotolight: 'robotolight';
$font-robotobold: 'robotobold';

// EMBED
@font-face {
  font-family: $font-robotothin;
  src: url('/assets/fonts/Roboto-Thin-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: $font-robotolight;
  src: url('/assets/fonts/Roboto-Light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: $font-robotomedium;
  src: url('/assets/fonts/Roboto-Medium-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: $font-robotoregular;
  src: url('/assets/fonts/Roboto-Regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: $font-robotoregular;
  src: url('/assets/fonts/Roboto-Bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

:root {
  font-size: 1rem;
}
