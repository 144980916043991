.hmh-form {
  padding: 15px 0;
  margin: 45px 0 10px 0;

  .hmh-box {
    padding: 45px 30px 25px 30px;

    @include media-breakpoint-up(sm) {
      padding: 45px 30px 25px 30px;
    }
    @include media-breakpoint-up(md) {
      padding: 45px 30px 25px 30px;
    }
    @include media-breakpoint-up(lg) {
      padding: 45px 30px 25px 30px;
    }
    @include media-breakpoint-up(xl) {
      padding: 45px 37px 25px 37px;
    }

    .row > * {
      padding-right: 3px;
      padding-left: 3px;
    }
  }

  h2 {
    float: left !important;
    margin-bottom: 45px;
    font-weight: 600;
    letter-spacing: 1px;

    @include media-breakpoint-up(md) {
      letter-spacing: $letterSpacingDesktop;

      @media not all and (min-resolution: 0.001dpcm) {
        @media all {
          letter-spacing: $letterSpacingDesktopSafari;
        }
      }
    }
  }

  textarea {
    min-height: 300px;
    line-height: 1.1;
    resize: none;
  }

  .form-check {
    padding: 15px 0;
    margin: 0 25px;
  }

  .form-check-label {
    color: #848383;
    font-family: $font-robotomedium;
    font-size: 13px;
  }

  .btn-open-privacy {
    color: #848383;
    text-decoration: none;
    // text-transform: uppercase;
    transition: color 0.35s cubic-bezier(0, 0, 0.23, 1);
    text-decoration: underline;

    &:hover {
      color: $color-white;
    }
  }

  p {
    margin-top: 5px !important;
    margin-bottom: 45px !important;
  }

  .hmh-form-success,
  .hmh-form-error {
    margin-top: 15px !important;
    margin-bottom: 25px !important;
  }

  .hmh-form-error {
    color: red;
  }

  .btn-home {
    color: $color-white;
    text-decoration: none;
    transition: color 0.35s cubic-bezier(0, 0, 0.23, 1);

    &:hover {
      color: $color-grey-dark;
    }
  }

  #hmh-p-text {
    font-family: $font-robotomedium;
    font-size: 13px;
    color: #848383;
    padding: 5px 5px 0 5px;
    margin-bottom: 15px !important;
  }
}
