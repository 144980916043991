.hmh-reasons {
  padding: 15px 0;

  .hmh-box {
    overflow: hidden;
    padding: 0;

    .carousel-indicators {
      margin-bottom: 10px;
    }

    .carousel-control-prev,
    .carousel-control-next {
      width: 5%;
    }
  }

  img {
    width: 100%;
    height: auto;
    padding: 0;
  }
}
