.hmh-footer {
  padding: 0 0 25px 0;

  @include media-breakpoint-up(md) {
    padding: 0 0 100px 0;
  }

  .hmh-footer-menu {
    padding: 14px 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @include media-breakpoint-up(md) {
      padding: 14px 5px;
    }

    @include media-breakpoint-up(lg) {
      padding: 14px 5px;
    }

    @include media-breakpoint-up(xl) {
      padding: 14px 12px;
    }

    a {
      color: $color-white;
      text-decoration: none;
      font-family: $font-robotomedium;
      text-transform: uppercase;
      transition: color 0.35s cubic-bezier(0, 0, 0.23, 1);
      cursor: pointer;
      font-size: 10px;

      &:hover {
        color: $color-grey-dark;
      }

      @include media-breakpoint-up(md) {
        font-size: 14px;
      }
    }
  }
}
